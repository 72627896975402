// extracted by mini-css-extract-plugin
export var bigCards = "styles-module--bigCards--2a29f";
export var card = "styles-module--card--76901";
export var cardName = "styles-module--cardName--05260";
export var cardText = "styles-module--cardText--9e3df";
export var cardsColumn = "styles-module--cardsColumn--b329c";
export var column = "styles-module--column--881e5";
export var container = "styles-module--container--b3763";
export var content = "styles-module--content--2379e";
export var description = "styles-module--description--7df38";
export var directionsBlock = "styles-module--directionsBlock--2c7db";
export var firstBlock = "styles-module--firstBlock--13870";
export var image = "styles-module--image--cf421";
export var lowCards = "styles-module--lowCards--ac4bc";
export var ourProjectsBlock = "styles-module--ourProjectsBlock--49ae9";
export var structureBlock = "styles-module--structureBlock--d9ba0";
export var texts = "styles-module--texts--e33db";
export var wrapper = "styles-module--wrapper--ee9b2";